.content {
  position: initial;
  background-color: white;
  padding: 8px;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border: 2px solid rgba(0, 0, 0, .06);
}

@primary-color: #1890ff;